<template>
  <div>
    <v-row>
      <v-col :cols="2">
        <v-img
          v-if="sundry.stock_product_data"
          :lazy-src="sundry.stock_product_data.thumbnail_url"
          max-width="50"
          :src="sundry.stock_product_data.thumbnail_url"
        />
      </v-col>
      <v-col :cols="10">
        <strong v-if="sundry.stock_product_data" style="cursor: pointer;">
          <span @click.stop="dialog = true">{{ sundry.name }}</span>
          <a v-if="sundry.stock_product_id" :href="`https://telescope.havebike.co.uk/products/${sundry.stock_product_id}`" target="_blank" style="text-decoration: none;">
            <v-icon class="ml-2" x-small>mdi-open-in-new</v-icon>
          </a>
        </strong>
        <span v-else>{{ sundry.name }}</span>

        <strong class="green--text ml-2">{{ sundry.qty > 1 ? `(x ${sundry.qty})` : '' }}</strong>
        <small v-if="sundry.safety_critical" class="red--text font-weight-bold ml-2">Safety Critical</small>
        <v-icon v-if="sundry.safety_critical" color="red darken-2" small right>mdi-alert-circle</v-icon>

        <div v-if="sundry.stock_product_data">
          <small v-if="sundry.stock_product_data.manufacturer">{{ sundry.stock_product_data.manufacturer.name }}</small>
        </div>

        <div>
          <v-btn
            depressed
            x-small
            class="mt-2"
            @click.stop="editDialog = true"
          >
            Edit
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-if="sundry.stock_product_data"
      v-model="dialog"
      scrollable
      max-width="800px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">{{ sundry.name }}</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Description</v-list-item-title>
              <v-list-item-subtitle>
                {{ sundry.stock_product_data.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Size</v-list-item-title>
              <v-list-item-subtitle>
                {{ sundry.stock_product_data.size }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>SKU</v-list-item-title>
              <v-list-item-subtitle>{{ sundry.stock_product_data.sku }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Barcode</v-list-item-title>
              <v-list-item-subtitle v-for="barcode in sundry.stock_product_data.barcodes" :key="barcode.id">{{ barcode.barcode }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Manufacturer</v-list-item-title>
              <v-list-item-subtitle>{{ sundry.stock_product_data.manufacturer.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>RRP</v-list-item-title>
              <v-list-item-subtitle>{{ $currency(sundry.stock_product_data.rrp) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="sundry.supplier" five-line>
            <v-list-item-content>
              <v-list-item-title>Supplier</v-list-item-title>
              <v-list-item-subtitle>{{ sundry.stock_product_data.supplier.name }}</v-list-item-subtitle>
<!--               <v-list-item-subtitle>{{ sundry.supplier.primary_contact_name }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ sundry.supplier.primary_contact_email }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ sundry.supplier.primary_contact_phone }}</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <EditSundryDialog
      :dialog.sync="editDialog"
      :sundry="sundry"
      :booking="sundry.bike.booking"
    />
  </div>
</template>

<script>
import EditSundryDialog from '@/components/sundries/EditSundryDialog'

export default {
  name: 'PartsToOrderPartColumn',

  components: {
    EditSundryDialog
  },

  props: {
    sundry: {
      type: Object,
      required: true
    },

    approveScreen: {
      type: Boolean,
      default: () => { return false },
      required: false
    }
  },

  data () {
    return {
      dialog: false,
      editDialog: false
    }
  }
}
</script>

<style lang="css" scoped>
</style>