<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot v-bind:on="on" v-bind:attrs="attrs">
          <v-btn
            v-user-can="deletePermission"
            text
            color="error"
            v-bind="attrs"
            v-on="on"
          >
            Delete
          </v-btn>
        </slot>
      </template>
      <v-card>
        <v-card-title class="headline red white--text">
          Delete {{ recordName }}
        </v-card-title>

        <v-card-text class="pt-4">
          {{ deleteText }}

          <FormErrors ref="formErrors" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
          <v-btn
            color="red"
            text
            :loading="loading"
            @click="deleteRecord"
          >
            Delete {{ recordName }}
            <v-icon small right>mdi-delete-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      {{ recordName }} deleted successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue deleting the {{ recordName }}.
    </SnackBar>
  </div>
</template>

<script>
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'DeleteDialog',

  components: {
    FormErrors,
    SnackBar
  },

  props: {
    recordName: {
      required: true,
      type: String
    },

    deletePermission: {
      required: true,
      type: String
    },

    deleteText: {
      required: true,
      type: String
    },

    path: {
      required: false,
      type: String,
      default: null
    },

    url: {
      required: false,
      type: String,
      default: null
    },

    xSmall: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      loading: false,
      dialog: false
    }
  },

  methods: {
    deleteRecord () {
      this.loading = true
      this.$refs.formErrors.clear()
      
      this.$api.delete({
        path: this.path,
        url: this.url
      })
        .then(() => {
          this.$refs.successBar.open()
          this.$emit('deleted')
          this.dialog = false
        })
        .catch(error => {
          if (this.$refs.formErrors) {
            this.$refs.formErrors.setErrors(error)
          }
          if (this.$refs.errorBar) {
            this.$refs.errorBar.open()
          }
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="css" scoped>
</style>