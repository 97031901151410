import keyBy from 'lodash.keyby'
import map from 'lodash.map'

export default {
  computed: {
    chartData () {
      var datasets = []
      if (this.metrics.datasets) {
        var labels = [...Array(this.metrics.labels.length).keys()]
        this.metrics.datasets.forEach((dataset, key) => {
          var bgColors = []
          var borderColors = []
          labels.forEach(() => {
            bgColors.push(dataset.key ? this.jobDesignatedColours[dataset.key]?.background || this.barBgColors[key] : this.barBgColors[key])
            borderColors.push(dataset.key ? this.jobDesignatedColours[dataset.key]?.border || this.barBorderColors[key] : this.barBorderColors[key])
          })
          datasets.push({
            ...dataset,
            backgroundColor: bgColors,
            borderColor: borderColors,
            borderWidth: 1
          })
        })
        var bgColors = []
        var borderColors = []
        labels.forEach(() => {
          bgColors.push('rgb(103, 58, 183, 0.3)')
          borderColors.push('rgb(103, 58, 183, 1)')
        })
        var totalData = []
        var data = [...Array(datasets[0]?.data?.length).keys()]
        data.forEach(() => {
          totalData.push(0)
        })
        datasets.push({
          label: 'Total',
          backgroundColor: bgColors,
          borderColor: borderColors,
          data: totalData,
          borderWidth: 1
        })
      }
      return datasets
    },

    barBgColors () {
      return [
        'rgba(0, 160, 155, 0.3)',
        'rgb(255, 148, 28, 0.3)',
        'rgb(33, 150, 243, 0.3)',
        'rgb(233, 30, 99, 0.3)',
        'rgb(280, 92, 36, 0.3)',
        'rgb(115, 168, 50, 0.3)',
        'rgb(113, 50, 168, 0.3)',
        'rgb(175, 29, 29, 0.3)',
        'rgb(31, 175, 29, 0.3)',
        'rgb(223, 212, 58, 0.3)',
        'rgb(58, 82, 223, 0.3)',
        'rgb(11, 20, 70, 0.3)',
        'rgb(224, 6, 147, 0.3)',
        'rgb(61, 250, 241, 0.3)',
        'rgb(212, 250, 61, 0.3)',
        'rgb(194, 146, 252, 0.3)',
        'rgb(249, 169, 21, 0.3)',
        'rgb(249, 16, 16, 0.3)',
        'rgb(16, 249, 74, 0.3)',
        'rgb(128, 0, 219, 0.3)',
      ]
    },

    barBorderColors () {
      return [
        'rgba(0, 160, 155, 1)',
        'rgb(255, 148, 28, 1)',
        'rgb(33, 150, 243, 1)',
        'rgb(233, 30, 99, 1)',
        'rgb(280, 92, 36, 1)',
        'rgb(115, 168, 50, 1)',
        'rgb(113, 50, 168, 1)',
        'rgb(175, 29, 29, 1)',
        'rgb(31, 175, 29, 1)',
        'rgb(223, 212, 58, 1)',
        'rgb(58, 82, 223, 1)',
        'rgb(11, 20, 70, 1)',
        'rgb(224, 6, 147, 1)',
        'rgb(61, 250, 241, 1)',
        'rgb(212, 250, 61, 1)',
        'rgb(194, 146, 252, 1)',
        'rgb(249, 169, 21, 1)',
        'rgb(249, 16, 16, 1)',
        'rgb(16, 249, 74, 1)',
        'rgb(128, 0, 219, 1)',
      ]
    },

    jobDesignatedColours () {
      return this.jobStates ? keyBy(
        map(this.jobStates, (key, i) => {
          return {
            key,
            background: this.barBgColors[i],
            border: this.barBorderColors[i]
          }
        }),
        'key'
      ) : false
    },

    chartConfig () {
      return {
        type: 'bar',
        data: {
          labels: this.metrics.labels,
          datasets: this.chartData
        },
        options: {
          title: {
            display: true,
            text: this.title
          },
          legend: {
            labels: {
              filter: (item) => {
                return !item.text.includes('Total')
              }
            }
          },
          tooltips: {
            position: 'nearest',
            mode: 'label',
            callbacks: {
              label: (tooltipItem, data) => {
                var label = data.datasets[tooltipItem.datasetIndex].label
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                var total = 0;
                for (var i = 0; i < data.datasets.length; i++)
                    total += data.datasets[i].data[tooltipItem.index];

                if (label === 'Total') {
                  return `${label} : ${total}`
                } else {
                  return `${label} : ${value}`
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                // suggestedMax: 50,
                beginAtZero: true,
                stepSize: 10
              }
            }]
          }
        }
      }
    }
  }
}