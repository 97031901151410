export default {
  data () {
    return {
      viewport: {
        mobileBreakpoint: 800,
        isDesktop: true,
        isMobile: false
      }
    }
  },

  beforeDestroy () {
    if (typeof window === 'undefined') {
      return
    }
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    onResize () {
      this.viewport.isDesktop = window.innerWidth >= this.viewport.mobileBreakpoint
      this.viewport.isMobile = window.innerWidth < this.viewport.mobileBreakpoint
    },

    resizeChart (key, size, sizeMobile = null) {
      if (this.$refs[key]) {
        this.$refs[key].parentNode.style.height = this.viewport.isMobile ? sizeMobile || size : size
        this.$refs[key].parentNode.style.width = '100%'
      }
    }
  }
}
