<template>
  <div :class="{ 'timer': timer }">
    <v-tooltip top v-if="serviceRecord.id">
      <template v-slot:activator="{ on }">
        <v-avatar size="30" color="green" v-on="on" v-if="serviceRecord.ended_by" class="record_img ended">
          <img
            v-if="serviceRecord.ended_by.profile_img_url"
            :src="serviceRecord.ended_by.profile_img_url"
            :alt="serviceRecord.ended_by.name">
          <strong v-else class="white--text" style="font-size: 10px;">{{ serviceRecord.ended_by.initials }}</strong>
        </v-avatar>
        <v-avatar size="30" color="orange" v-on="on" v-else-if="serviceRecord.started_by" class="record_img started">
          <img
            v-if="serviceRecord.started_by.profile_img_url"
            :src="serviceRecord.started_by.profile_img_url"
            :alt="serviceRecord.started_by.name">
          <strong v-else class="white--text" style="font-size: 10px;">{{ serviceRecord.started_by.initials }}</strong>
        </v-avatar>
      </template>
      <span v-if="serviceRecord.ended_by && serviceRecord.started_by">
        {{ serviceRecord.ended_by.name }} (Started At: {{ $momentDateTimeHuman(serviceRecord.started_at) }} / Ended At: {{ $momentDateTimeHuman(serviceRecord.ended_at) }})
      </span>
      <span v-else-if="serviceRecord.ended_by">
        {{ serviceRecord.started_by.name }} (Started At: {{ $momentDateTimeHuman(serviceRecord.started_at) }})
      </span>
      <span v-else-if="serviceRecord.started_by">
        {{ serviceRecord.started_by.name }} (Started At: {{ $momentDateTimeHuman(serviceRecord.started_at) }})
      </span>
    </v-tooltip>
    <span v-else>-</span>
    <div v-if="timer" class="ml-2 counter_outer">
      <strong>
        <small>
          {{ countUp }}
        </small>
      </strong>
      <span v-if="job.service.performance_time_threshold_minutes < countUpMins" class="dot"></span>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'WorkshopDashboardServiceRecordCol',

  props: {
    job: {
      type: Object,
      required: true
    },

    serviceRecord: {
      type: Object,
      required: true
    },

    timer: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },

  data () {
    return {
      countUp: 'hh:mm:ss',
      countUpMins: 0
    }
  },

  mounted () {
    setInterval(() => {
      var countFrom = new Date(this.serviceRecord.started_at).getTime()
      var now = new Date()
      var timeDifference = (now - countFrom)

      var secondsInADay = 60 * 60 * 1000 * 24
      var secondsInAHour = 60 * 60 * 1000

      this.countUpMins = timeDifference / 60000

      var days = Math.floor(timeDifference / (secondsInADay) * 1);
      var hours = Math.floor((timeDifference % (secondsInADay)) / (secondsInAHour) * 1);
      var mins = Math.floor(((timeDifference % (secondsInADay)) % (secondsInAHour)) / (60 * 1000) * 1);
      var secs = Math.floor((((timeDifference % (secondsInADay)) % (secondsInAHour)) % (60 * 1000)) / 1000 * 1);

      if (days && days < 10) {
        days = `0${days}`
      }
      if (hours < 10) {
        hours = `0${hours}`
      }
      if (mins && mins < 10) {
        mins = `0${mins}`
      }
      if (secs && secs < 10) {
        secs = `0${secs}`
      }

      if (!days) {
        this.countUp = `${hours || '0'}:${mins || '00'}:${secs || '00'}`
      } else {
        this.countUp = `${days || '0'}:${hours || '0'}:${mins || '00'}:${secs || '00'}`
      }
    }, 1000)
  }
}
</script>
<style type="text/css">
  .counter_outer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .record_img {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .record_img.started {
    border: solid orange 3px;
  }
  .record_img.ended {
    border: solid green 3px;
  }
  .timer {
    min-width: 120px;
    position: relative;
    display: flex;
  }
  .dot { 
    background-color: #f44336; 
    border-radius: 50%; 
    width: 10px; 
    height: 10px; 
    display: block;
    right: 0;
    z-index: 1; 
    position: absolute; 
  }
  .dot::before { 
    background-color: #f44336; 
    content: ''; 
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    animation: burst-animation 1s infinite;
    animation-fill-mode: forwards; 
    z-index: 0;
  }
  @keyframes burst-animation {
    from { opacity: 1; transform:scale(1); }
    to { opacity: 0; transform:scale(4); }
  }
</style>
