<template>
	<AlgoliaSearch
    v-model="localValue"
    v-bind="$attrs"
    :dense="false"
    :label="$attrs.disabled ? 'Please choose a bike' : `${labour ? 'Labour Code' : 'Part'} Search (${labour ? 'search by name, code, area of fitting etc.' : 'search by name, code, manufacturer, supplier etc.'})`"
    :index="searchIndex"
    :request-params="{ facetFilters }"
    @change="$emit('update', $event)"
  >
    <template #empty>
      <v-list-item>
        <v-list-item-title>Start typing to find a {{ labour ? 'service to add' : 'part or product to add' }}...</v-list-item-title>
      </v-list-item>
    </template>
    <template slot-scope="data">
      <v-list-item-avatar v-if="data.item.thumbnail_url">
        <v-img :src="data.item.thumbnail_url" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-if="data.item._highlightResult.name" class="search_highlight"><strong v-html="data.item._highlightResult.name.value" /></v-list-item-title>
        <v-list-item-subtitle v-if="data.item._highlightResult.description" class="search_highlight mb-2" v-html="data.item._highlightResult.description.value" />
        <div v-if="!labour" class="search_item_meta">
          <v-list-item-subtitle class="mb-2">
            <strong :class="{
              'green--text': inStock(data.item),
              'red--text': !inStock(data.item)
            }">
              {{ inStock(data.item) ? `In Stock at ${$hubs.currentHub.name}` : `Out of Stock at ${$hubs.currentHub.name}` }}
            </strong>
          </v-list-item-subtitle>
          <v-alert v-if="inStock(data.item)" color="green" text dense outlined>
            <div v-for="(stock_location, key) in stockLocations(data.item)" :key="stock_location.id">
              <v-divider v-if="key !== 0" class="mt-1 mb-1" />
              <small>
                <strong class="search_highlight" v-html="data.item._highlightResult.hubs[$hubs.currentHub.id].stock_locations[key].name.value" /> ({{ stock_location.allocation.stock_available }} available & {{ stock_location.allocation.allocated }} allocated)
              </small>
            </div>
          </v-alert>
          <v-alert v-else color="red" text dense outlined>
            <div v-if="data.item.suppliers.length > 0">
              <div v-for="(supplier, key) in data.item.suppliers" :key="supplier.id">
                <small>
                  <strong class="search_highlight" v-html="data.item._highlightResult.suppliers[key].name.value" /> ({{ supplier.purchase_data.estimated_available_date ? 'Available from ' + $momentDate(supplier.purchase_data.estimated_available_date) : 'Availability unkown' }})
                </small>
              </div>
            </div>
            <small v-else>
              No suppliers setup for this product
            </small>
          </v-alert>
          <v-list-item-subtitle v-if="data.item.sku">
            <strong>Code:</strong> <span class="search_highlight" v-html="data.item._highlightResult.sku.value" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="data.item.size">
            <strong>Size:</strong> <span class="search_highlight" v-html="data.item._highlightResult.size.value" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="data.item._highlightResult.manufacturer && data.item._highlightResult.manufacturer.name">
            <strong>Manufacturer:</strong> <span class="search_highlight" v-html="data.item._highlightResult.manufacturer.name.value" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="data.item.basic_colour">
            <strong>Colour:</strong> <span class="search_highlight" v-html="data.item._highlightResult.basic_colour.value" />
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mt-2">
            <v-chip v-if="data.item.tag_1" class="search_highlight" outlined small v-html="data.item._highlightResult.tag_1.value" />
            <v-chip v-if="data.item.tag_2" class="search_highlight ml-1" outlined small v-html="data.item._highlightResult.tag_2.value" />
            <v-chip v-if="data.item.tag_3" class="search_highlight ml-1" outlined small v-html="data.item._highlightResult.tag_3.value" />
          </v-list-item-subtitle>
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text>
          <strong>{{ $currency(data.item.rrp) }}</strong>
        </v-list-item-action-text>
        <v-list-item-action-text v-if="data.item.is_labour && data.item.price_with_service"><v-icon x-small color="green">mdi-wrench-outline</v-icon> <strong class="green--text">{{ $currency(data.item.price_with_service) }}</strong></v-list-item-action-text>
        <v-list-item-action-text>{{ data.item.supplier_name }}</v-list-item-action-text>
        <v-list-item-action-text v-if="data.item.estimated_delivery_date">
          <strong class="red--text">Expected: {{ $momentDate(data.item.estimated_delivery_date) }}</strong>
        </v-list-item-action-text>
      </v-list-item-action>
    </template>
  </AlgoliaSearch>
</template>
<script>
import HasVModel from '@/mixins/HasVModel'
import AlgoliaSearch from '@/components/algolia-search/AlgoliaSearch'

export default {
	name: 'SundryAlgoliaSearch',

	components: {
		AlgoliaSearch
	},

	mixins: [ HasVModel ],

	props: {
		labour: {
			type: Boolean,
			required: false,
			default: () => { return false }
		},

    bike: {
      type: Object,
      required: true
    }
	},

	computed: {
		searchIndex () {
			return this.labour ? 'atlas_products' : 'telescope_products'
		},

    facetFilters () {
      var filters = []
      if (this.labour) {
        filters.push('is_labour:true')
        if (this.bike.job_type === 'JOB_TYPE_FLEET') {
          filters.push('type:TYPE_FLEET')
        } else {
          filters.push('type:TYPE_C&R')
        }
      }

      return filters
    }
	},

  methods: {
    stockLocations (item) {
      return item.hubs[this.$hubs.currentHub.id] ? item.hubs[this.$hubs.currentHub.id].stock_locations : []
    },

    inStock (item) {
      return item.hubs[this.$hubs.currentHub.id] ? item.hubs[this.$hubs.currentHub.id].in_stock : false
    }
  }
}
</script>
