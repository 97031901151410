<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title :class="{'primary': !form.safety_critical, 'red lighten-1': form.safety_critical, 'white--text': true }">
          <span class="headline">Edit Sundry - {{ form.safety_critical ? 'Safety Critical Sundry' : 'Sundry' }}</span>

          <v-spacer></v-spacer>

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <DeleteDialog
                :path="`bookings/${this.booking.id}/sundries/${this.sundry.id}`"
                :delete-text="`Are you sure you want to delete this sundry?`"
                record-name="Sundry"
                delete-permission="DELETE_BOOKING_SUNDRIES"
                @deleted="closeDialog"
              >
                <template v-slot="{ on, attrs }">
                  <v-list-item
                    v-user-can:DELETE_BOOKING_SUNDRIES
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-title>Delete Sundry</v-list-item-title>
                  </v-list-item>
                </template>
              </DeleteDialog>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <h3 v-if="showProductSelector" class="mb-4 primary--text">1. Sundry Product</h3>
            <v-alert v-if="showProductSelector" type="info" text outlined dense>
              This sundry isn't linked to a product. You can link it below.
            </v-alert>
            <v-row v-if="showProductSelector">
              <v-col
                cols="12"
              >
                <SundryAlgoliaSearch
                  v-model="form.item_sku"
                  :labour="form.is_labour"
                  :bike="sundry.bike"
                  autofocus
                />
              </v-col>
            </v-row>

            <h3 class="mb-4 primary--text">{{ !showProductSelector ? '1.' : '2.' }} Sundry Information</h3>
            <v-row>
              <v-col
                cols="12"
                :sm="!form.is_labour ? 8 : 12"
              >
                <v-text-field
                  v-model="form.name"
                  :rules="[v => !!v || 'Sundry name is required']"
                  label="Sundry Name"
                  hint="The line item name for this sundry - this is what the customer will see."
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-col
                v-if="!form.is_labour"
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.manufacturer"
                  label="Manufacturer"
                  hint="The manufacturer of this part."
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.qty"
                  :rules="[v => !!v || 'Quantity is required']"
                  type="number"
                  step="1"
                  min="1"
                  label="Quantity"
                  hint="The number of parts taken for this sundry"
                  outlined
                  required
                  dense
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="form.price"
                  :rules="[v => !!v || 'Total price is required']"
                  type="number"
                  step="0.01"
                  prefix="£"
                  label="Total Price"
                  hint="The total price of this sundry - this is what the customer will see."
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  :rules="[v => !!v || 'Reason is required']"
                  rows="2"
                  :label="form.is_labour ? 'Reason For Work (text visible to customer)' : 'Reason For Replacement (text visible to customer)'"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-divider class="mb-10 mt-2" />

            <h3 class="mb-4 primary--text">{{ !showProductSelector ? '2.' : '3.' }} Sundry Type</h3>

            <v-row>
              <v-col cols="12">
                <v-alert
                  dense
                  text
                  color="red lighten-1"
                  outlined
                >
                  <p class="mb-0 pb-0"><strong>Is this sundry safety critical?</strong></p>
                  <p class="mt-0 pt-0">Please tick the box if this sundry is related to {{ form.is_labour ? 'work' : 'a part' }} that <b><u>IS</u></b> safety critical.</p>
                  <v-checkbox
                    v-model="form.safety_critical"
                    :label="form.safety_critical ? `This ${form.is_labour ? 'work' : 'part'} IS safety critical` : `This ${form.is_labour ? 'work' : 'part'} is NOT safety critical`"
                    hide-details
                  />
                </v-alert>
              </v-col>

              <v-col v-if="availableBikeSundries.length > 0 && this.form.is_labour" cols="12">
                <v-select
                  v-model="form.associated_sundry_id"
                  :items="availableBikeSundries"
                  :label="`Associated ${this.form.is_labour ? 'Part' : 'Labour / Fitting'}`"
                  :item-text="(sundry) => { return sundry.name }"
                  :hint="`If you wish to associate this sundry with ${form.is_labour ? 'a part' : 'labour'}, please select it here.`"
                  item-value="id"
                  clearable
                  persistent-hint
                  outlined
                  required
                  dense
                >
                  <template v-slot:item="{ item: sundry }">
                    <v-list-item-content>
                      <v-list-item-title><strong>{{ sundry.name }}</strong></v-list-item-title>
                      <v-list-item-subtitle v-text="sundry.description" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>
                        <span class="green--text">{{ sundry.qty > 1 ? `(x ${sundry.qty}) ` : null }}</span>
                        <strong>{{ $currency(sundry.price) }}</strong>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                  <template v-slot:selection="{ item: sundry }">
                    <v-list-item-content>
                      <v-list-item-title><strong>{{ sundry.name }}</strong></v-list-item-title>
                      <v-list-item-subtitle v-text="sundry.description" />
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            
            <v-divider v-if="!form.is_labour" class="mb-6 mt-2" />

            <h3 v-if="!form.is_labour" class="mb-4 primary--text">{{ !showProductSelector ? '3.' : '4.' }} Sundry Information</h3>
            <p v-if="!form.is_labour">Extra information about the sundry, such as stock etc.</p>     

            <v-row v-if="!form.is_labour">
              <v-col cols="12">
                <v-alert
                  dense
                  text
                  :color="form.in_stock ? 'green' : 'red'"
                  outlined
                >
                  <p class="mb-0 pb-0">
                    <strong>Do we have this item is stock?</strong>
                  </p>
                  <p>Please click the box below if we have this item in stock and ready to use.</p>
                  <v-divider class="mb-3 mt-2" />
                  <v-checkbox
                    v-model="form.in_stock"
                    :label="form.in_stock ? 'Item IS in stock at havebike' : 'Item IS NOT in stock at havebike'"
                  />
                </v-alert>
              </v-col>
            </v-row>

            <v-divider class="mb-6 mt-2" />

            <h3 class="mb-4 primary--text">{{ form.is_labour ? (!showProductSelector ? '3.' : '4.') : (!showProductSelector ? '4.' : '5.') }} Billing Information</h3>
            <p>If this sundry is being added before the customer has approved the spend, please review the information below.</p>     
            <v-row>
              <v-col
                cols="12"
              >
                <v-checkbox
                  v-model="form.estimate"
                  :label="form.estimate ? 'Added BEFORE customer approved spend' : 'Added AFTER customer approved spend'"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-checkbox
                  v-model="form.declined"
                  :label="form.declined ? 'Customer HAS declined this work' : 'Customer has NOT declined this work'"
                />
              </v-col>
            </v-row>

            <FormErrors ref="formErrors" />          
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn :color="form.safety_critical ? 'red' : 'primary'" :loading="loading" text @click="updateSundry">Edit Sundry <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar success ref="successBar">
      Sundry edited successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue editing the sundry.
    </SnackBar>
  </div>
</template>

<script>
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import HasBooking from '@/mixins/HasBooking'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'
import DeleteDialog from '@/components/DeleteDialog'
import HasAssociatedSundries from '@/mixins/HasAssociatedSundries'
import SundryAlgoliaSearch from '@/components/sundries/SundryAlgoliaSearch'

export default {
  name: 'EditSundryDialog',

  mixins: [ HasDialog, HasForm, HasBooking, HasAssociatedSundries ],

  components: {
    FormErrors,
    SnackBar,
    DeleteDialog,
    SundryAlgoliaSearch
  },

  props: {
    sundry: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      expectedDeliveryDateDialog: false,
      loading: false
    }
  },

  watch: {
    dialog (dialog) {
      this.form = {
        ...this.form,
        ...this.sundry
      }

      if (this.sundry.estimated_delivery_date) {
        this.$set(this.form, 'estimated_delivery_date', this.$moment(this.sundry.estimated_delivery_date).format('YYYY-MM-DD'))
      }

      if (dialog) {
        this.getSundries()
      }
    },

    'form.item_sku' (item) {
      if (item && (this.form.item_sku?.id !== this.sundry.item_sku?.id)) {
        this.$set(this.form, 'supplier_id', null)
        this.$set(this.form, 'estimated_delivery_date', null)
        if (item.name) {
          this.$set(this.form, 'name', item.name)
        }
        if (item.in_stock) {
          this.$set(this.form, 'location', item.stock_locations[0])
        }
        if (!item.in_stock && typeof item.suppliers !== 'undefined' && item.suppliers.length > 0 && item.suppliers[0]?.purchase_data?.estimated_delivery_date) {
          this.$set(this.form, 'estimated_delivery_date', this.$moment(item.suppliers[0]?.purchase_data?.estimated_delivery_date).format('YYYY-MM-DD'))
        }
        this.$set(this.form, 'manufacturer', item.manufacturer?.name)
        if (item.estimated_delivery_date) {
          this.$set(this.form, 'estimated_delivery_date', this.$moment(item.estimated_delivery_date).format('YYYY-MM-DD'))
        }
      }
    }
  },

  computed: {
    showProductSelector () {
      if (this.sundry.stock_product_id) {
        return false
      }
      if (this.form.location_id) {
        return false
      }

      return true
    }
  },

  methods: {
    updateSundry () {
      if (this.$refs.form.validate()) {
        this.$refs.formErrors.clear()
        this.loading = true
        this.$api.persist('put', {
          path: `bookings/${this.booking.id}/sundries/${this.sundry.id}`,
          object: {
            ...this.form,
            ...{
              item_sku: (this.form.item_sku ? this.form.item_sku.id : null) ? this.form.item_sku.sku : this.form.item_sku,
              in_stock: this.form.in_stock ? true : false,
              is_labour: this.form.is_labour ? true : false,
              declined: this.form.declined ? true : false,
              safety_critical: this.form.safety_critical ? true : false,
              supplier_id: this.form.supplier_id?.id,
              reason: this.form.reason?.value,
              location_id: this.form.location?.allocation?.id,
              stock_product_id: this.form.item_sku?.id,
              location_name: this.form.location ? `${this.form.location.name} (${this.form.location.short_name})` : null,
              estimated_delivery_date: this.form.is_labour ? null : this.form.estimated_delivery_date,
              manufacturer: this.form.is_labour ? null : this.form.manufacturer
            }
          }
        })
          .then(({ data: sundry }) => {
            this.$refs.successBar.open()
            this.$emit('sundry:updated', sundry)
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>