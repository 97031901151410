<template>
  <div style="position: relative;">
    <canvas ref="chart" />
  </div>
</template>
<script type="text/javascript">
import HasMetrics from '@/mixins/HasMetrics'
import HasTable from '@/mixins/HasTable'
import HasViewportCheck from '@/mixins/HasViewportCheck'
import Chart from 'chart.js'
import filter from 'lodash.filter'
import sum from 'lodash.sum'

export default {
  name: 'TboMetricsSummary',

  mixins: [ HasMetrics, HasTable, HasViewportCheck ],

  props: {
    title: {
      type: String,
      required: true
    },

    fleet: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    start: {
      type: String,
      required: false,
      default: () => { return null }
    },

    end: {
      type: String,
      required: false,
      default: () => { return null }
    },

    excludeWorkshopStatus: {
      type: Array,
      required: false,
      default: () => { return null }
    }    
  },

  data () {
    return {
      jobStates: []
    }
  },

  watch: {
    'viewport.isMobile': {
      handler () {
        this.resizeChart('chart', '300px', '500px')
      }
    }
  },

  mounted () {
    this.getJobStates()
  },

  methods: {
    mountChart () {
      new Chart(
        this.$refs.chart,
        this.chartConfig
      )
    },

    getJobStates () {
      this.$api.get({
        path: 'job-workshop-states'
      })
        .then((data) => {
          this.jobStates = Object.keys(data)
        })
    },

    getMetrics () {
      this.$api.get({
        path: this.fleet ? 'bookings/metrics/tbo-fleet' : 'bookings/metrics/tbo',
        params: {
          start: this.start || this.$momentDateFormat(),
          end: this.end || this.$momentDateFormat(this.$moment().add(7, 'days')),
          exclude_workshop_status: this.excludeWorkshopStatus || []
        }
      })
      .then(({ data: metrics }) => {
        this.metrics = {
          ...metrics,
          datasets: filter(metrics.datasets, (field) => {
            return field?.data ? sum(field.data) > 0 : true
          })
        }
      })
      .then(() => {
        this.mountChart()
        this.resizeChart('chart', '300px', '500px')
      })
    }
  }
}
</script>
