import filter from 'lodash.filter'

export default {
  data () {
    return {
      bikeSundries: []
    }
  },

  computed: {
    availableBikeSundries () {
      return filter(this.bikeSundries, (sundry) => {
        if (this.form.is_labour) {
          return !sundry.is_labour || sundry.is_labour === null
        }

        return sundry.is_labour
      })
    }
  },

  methods: {
    getSundries () {
      this.$api.get({
        path: `bookings/${this.booking.id}/sundries`,
        params: {
          bikeId: this.selectedBike ? this.selectedBike.id : this.sundry?.bike?.id, 
          noAssociated: true,
          orderBy: 'created_at',
          ascending: false,
          limit: 10 * 10 * 10
        }
      })
      .then(({ data: sundries }) => {
        this.bikeSundries = sundries

        if (typeof this.defaultAssociatedSundry !== undefined) {
          if (this.defaultAssociatedSundry?.id) {
            this.$set(this.form, 'associated_sundry', this.defaultAssociatedSundry)
          }
        }
      })
    }
  }
}