import orderBy from 'lodash.orderby'
import filter from 'lodash.filter'
export default {
  computed: {
    orderedServiceRecords () {
      return orderBy(this.job.service_records, 'created_at', 'desc')
    },

    triageServiceRecord () {
      var record = filter(this.orderedServiceRecords, ['type', 'TYPE_TRIAGE'])
      if (record[0]) {
        return record[0]
      }
      return {}
    },

    triageConfirmationServiceRecord () {
      var record = filter(this.orderedServiceRecords, ['type', 'TYPE_TRIAGE_CONFIRMATION'])
      if (record[0]) {
        return record[0]
      }
      return {}
    },

    cleanServiceRecord () {
      var record = filter(this.orderedServiceRecords, ['type', 'TYPE_CLEAN'])
      if (record[0]) {
        return record[0]
      }
      return {}
    },

    qualityControlServiceRecord () {
      var record = filter(this.orderedServiceRecords, ['type', 'TYPE_QUALITY_CONTROL'])
      if (record[0]) {
        return record[0]
      }
      return {}
    },


    serviceRecord () {
      var record = filter(this.orderedServiceRecords, ['type', 'TYPE_SERVICE_RECORD'])
      if (record[0]) {
        return record[0]
      }
      return {}
    }
  }
}